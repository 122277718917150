// Types
import {
  AuthorityParty,
  InstanceHistory,
  MaterialisedPropertyData, SignedStates,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { Binder } from 'immer-yjs/src/immer-yjs';

import { Maybe } from '../../../../types/Utility';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { propertyFolderMaskDataNotRelevant, transformPropertyFolderDataForDisplay } from '../../../pdfgen/display-transformations';
import { FormUtil } from '../../../form';

import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { PdfWorkerDocumentDefinition } from '../../../pdf/pdf-worker-types';
import { CustomObjects, DefinitionMode, IPdfDefinitionProvider } from '../../../../types/PDFDefinition';
import { DiffCollection } from '../../../../types/DiffCollection';
import { salesContractTerminationExternal } from '../documents/sales-contract-termination-external';
import { FormTypes } from '../../../../yjs-schema/property/form';
import { compareFormInstances } from '../../../compareFormInstances';
import { SignatureSection } from '../../sections/signatureSection';

export class SaleContractTerminationExternalPdfDefinitionProvider implements IPdfDefinitionProvider {
  constructor(
    private dataBinder: Maybe<Binder<MaterialisedPropertyData>>,
    private metaBinder: Maybe<Binder<TransactionMetaData>>,
    private formCode: string,
    private formId: string,
    private debounce: boolean
  ) { }

  shouldDebounce(): boolean {
    return this.debounce;
  }

  getCoverPage() {
    return Promise.resolve(undefined);
  }

  getCoverPageDefinitionForPdfWorker(): Promise<any> {
    return Promise.resolve(undefined);
  }

  async getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    lastSignedSnapData?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride: TransactionMetaData
    }
  ): Promise<PdfWorkerDocumentDefinition> {
    const { metaOverride } = opts??{};

    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }
    const propertyRaw = propertyFolderMaskDataNotRelevant(this.dataBinder.get());
    const meta = metaOverride??this.metaBinder.get();
    const familyState = FormUtil.getFormFamilyState(this.formCode, meta);

    const formMetadata = FormUtil.getFormInstanceFromFamilyState(familyState, this.formId);
    const clausesId = familyState?.clauseChildId;
    const annexures = FormUtil.getAnnexures(this.formCode, this.formId, meta, { includeRestored: true }) ?? [];

    if (!formMetadata) {
      throw new Error('Cannot generate preview for nonexistent form');
    }

    const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;

    const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
    partyMap.set(SigningPartySourceType.Vendor, property?.vendors??[]);
    partyMap.set(SigningPartySourceType.Purchaser, property?.purchasers??[]);

    // Because the termination has no signers of its own, we will need to grab the latest signing parties from a non
    // termination document
    const signedDocuments = familyState?.instances
      ?.filter(i=>!FormTypes[i.formCode].isTermination && SignedStates.has(i.signing.state))
      ?.sort(compareFormInstances);
    const formInstanceOfLastSigned = signedDocuments?.[0];
    const signers = formInstanceOfLastSigned
      ? await SignatureSection.buildSignersFromSigningSession(formInstanceOfLastSigned, propertyRaw)
      : [];

    return {
      objects,
      brand,
      property,
      propertyRaw,
      signers,
      formType: 'salesContractTerminationExternalPDF',
      formFamilyState: familyState,
      formInstance: undefined,
      clausesId,
      annexures,
      lastSignedSnapData,
      snapshotHistory,
      changeSet,
      noBoldContentMode: noBoldContentMode??false,
      memberEntities
    };
  }

  async getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    changeOriginal?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ): Promise<TDocumentDefinitions> {
    const definition = await this.getDefinitionForPdfWorker(
      mode,
      brand,
      agencyName,
      objects,
      changeSet,
      changeOriginal,
      snapshotHistory,
      noBoldContentMode,
      memberEntities
    );

    return salesContractTerminationExternal(definition);
  }
}
