import { CSSProperties } from 'react'; // Type only import
import { UserPreferencesMain } from '@property-folders/contract/yjs-schema/user-preferences';
import { UpdateFn } from 'immer-yjs/src/immer-yjs';
import { getAgentThumbnailStyle } from '../avatar';
import { MaybeUpdateFn } from '../types/MaybeUpdateFn';

export class UserUtil {
  static getInitials(name?: string): string {
    const allInitials = name?.split(' ').filter(part => part).map(part => part[0]);
    if (!allInitials?.length) {
      return '??';
    }

    if (allInitials.length === 1) {
      return allInitials[0];
    }

    const first = allInitials[0];
    const last = allInitials[allInitials.length-1];

    return `${first}${last}`;
  }

  static getThumbnailStyle(name: string, agentId?: number): CSSProperties {
    return getAgentThumbnailStyle(name, agentId);
  }

  static addFavouritePropertyUpdateFn(propertyId: string): UpdateFn<UserPreferencesMain> {
    return (state) => {
      if (state.starredPropertyIds.indexOf(propertyId) >= 0) {
        return;
      }
      state.starredPropertyIds.push(propertyId);
    };
  }

  static removeFavouritePropertyUpdateFn(propertyId: string): UpdateFn<UserPreferencesMain> {
    return (state) => {
      const idx = state.starredPropertyIds.indexOf(propertyId);
      if (idx < 0) {
        return;
      }

      state.starredPropertyIds.splice(idx, 1);
    };
  }

  static toggleFavouriteProperty(id: string, starred: boolean, update: MaybeUpdateFn<UserPreferencesMain>) {
    if (!update) {
      return;
    }

    if (starred) {
      return update(this.removeFavouritePropertyUpdateFn(id));
    }

    update(this.addFavouritePropertyUpdateFn(id));
  }
}
